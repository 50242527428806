import {  useContext, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AuthContext } from "../../shared/context/auth-context";
import { APP_NAME } from "../../shared/GLOBAL_CONSTANTS";

const Header = () => {
  const auth = useContext(AuthContext);

  return (
    <Fragment>
     
        <h1 className="text-2xl font-bold p-3 font-mono "> {APP_NAME}</h1>
        {auth.isLoggedIn && (
          <p
            onClick={auth.logout}
            className="w-20 text-sm rounded-md bg-red-200 text-black shadow-md p-1 text-center mr-3 hover:cursor-pointer hover:bg-red-300"
          >
            Sign Out
          </p>
        )}

    </Fragment>
  );
};

export default Header;
