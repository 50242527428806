import { Routes, Route, Navigate } from "react-router-dom";

import HomePage from "./HomePage/HomePage";
import ChatPage from "./ChatPage/ChatPage";
import VerifyAccountActivationPage from "./VerificationPage/VerifyAccountActivation";
import Header from "./shared/Layout/Header";
import PageLayout from "./shared/Layout/PageLayout";

import RessetPassword from "./VerificationPage/ResetPassword";
import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";
import { Fragment } from "react";

function App() {
  const { authToken, login, logout, username, authLoaded, socket } = useAuth();

  let routes;

  if (authLoaded[0] && authLoaded[1]) {
    if (authToken) {
      if (authLoaded[2]) {
        routes = (
          <AuthContext.Provider
            value={{
              isLoggedIn: !!authToken,
              authToken: authToken,
              username: username,
              socket: socket,
              login: login,
              logout: logout,
            }}
          >
            <PageLayout>
           
              <Routes>
                {/* <Route path="/" element={<HomePage />} /> */}
                <Route path="chat" element={<ChatPage />} />
                <Route
                  path="verify-account-activation/:username/:verificationToken"
                  element={<VerifyAccountActivationPage />}
                />
                <Route
                  path="reset-password/:verificationToken"
                  element={<RessetPassword />}
                />
                <Route
                  path="*"
                  element={<Navigate to="/chat" replace={true} />}
                />
              </Routes>
            </PageLayout>
          </AuthContext.Provider>
        );
      }
    } else {
      routes = (
        <AuthContext.Provider
          value={{
            isLoggedIn: !!authToken,
            authToken: authToken,
            username: username,
            login: login,
            logout: logout,
          }}
        >
          <PageLayout>
          
            <Routes>
              <Route path="/" element={<HomePage />} />
              {/* <Route
              path="verify-account-activation/:username/:verificationToken"
              element={<VerifyAccountActivationPage />}
             /> */}
              <Route path="/verify-account-activation">
                <Route path=":username">
                  <Route
                    path=":verificationToken"
                    element={<VerifyAccountActivationPage />}
                  />
                </Route>
              </Route>
              <Route
                path="reset-password/:verificationToken"
                element={<RessetPassword />}
              />
              <Route path="*" element={<Navigate to="/" replace={true} />} />
            </Routes>
          </PageLayout>
        </AuthContext.Provider>
      );
    }
  }

  return (
    <Fragment>
      {!routes && <h1>Loading...</h1>}
      {routes && routes}
    </Fragment>
  );
}

export default App;
