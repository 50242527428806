import React, { useState, useEffect, useContext } from "react";

import { AuthContext } from "../../../shared/context/auth-context";

const MessageInput = (props) => {
  const auth = useContext(AuthContext);
  const [newMessage, setNewMessage] = useState();

  const sendMessageHandler = async (event) => {
    event.preventDefault();
    try {
      const url_req =
        process.env.REACT_APP_BACKEND_URL + "/api/chat/post-new-message";
      const apiResponseObj = await fetch(url_req, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.authToken}`,
        },
        body: JSON.stringify({
          userPair: props.userPair,
          message: newMessage,
          sender: auth.username,
        }),
      });
      const apiResponse = await apiResponseObj.json();

      props.updateMessages();

      setNewMessage("");
      
      const receipent = props.userPair.replace("-","").replace(auth.username,"")

      auth.socket.emit("new message", `${receipent}: ${newMessage}`);
    
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    auth.socket.on("update message", (data) => {
      props.updateMessages();
    });
  }, []);





  const onEnterPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      sendMessageHandler(event);
    }
  };

  return (

      <form onSubmit={sendMessageHandler} onKeyDown={onEnterPress} className="flex flex-row justify-center items-center  w-full max-w-md  h-full ">
        <textarea
        className="w-[85%] h-full p-1 text-sm border border-solid border-zinc-200 "
          id="text-area"
          name="newMessage"
          placeholder="Type your message here..."
          value={newMessage}
          onChange={(event) => setNewMessage(event.target.value)}
        />
        <input type="submit" value="Send" className=" w-[15%] h-full p-2 py-3 border border-solid border-zinc-200 bg-gray-100"/>
      </form>

  );
};

export default MessageInput;
