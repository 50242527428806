import { useState, useCallback, useEffect } from "react";
import io from "socket.io-client";

let logoutTimer;

export const useAuth = () => {
  const [authToken, setAuthToken] = useState(false);
  const [authTokenExpirationDate, setAuthTokenExpirationDate] = useState();
  const [username, setUsername] = useState(false);
  const [authLoaded, setAuthLoaded] = useState([false, false, false]);
  //const [loginLoaded, setLoginLoaded] = useState(false)
  const [socket, setSocket] = useState()
  //const socket = useRef();

  const login = useCallback((username, authToken, expirationDate) => {
    setAuthToken(authToken);
    setUsername(username);
    const authTokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);
    setAuthTokenExpirationDate(authTokenExpirationDate);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        username: username,
        authToken: authToken,
        expiration: authTokenExpirationDate.toISOString(),
      })
    );
  }, []);

  const logout = useCallback(() => {
    setAuthToken(null);
    setAuthTokenExpirationDate(null);
    setUsername(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    if (authToken && authTokenExpirationDate) {
      const remainingTime =
        authTokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
    setAuthLoaded((prev) => [true, prev[1], prev[2]]);
  }, [authToken, logout, authTokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.authToken &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.username,
        storedData.authToken,
        new Date(storedData.expiration)
      );
    }
    setAuthLoaded((prev) => [prev[0], true, prev[2]]);
  }, [login]);

  useEffect(() => {
    if (!!authToken) {
      try {
        const thisSocket = io(process.env.REACT_APP_SOCKET_URL, {
          auth: {
            token: `Bearer ${authToken}`,
          },
          query: {
            username: username,
          },
        });
        setSocket(thisSocket)
        setAuthLoaded((prev) => [prev[0], prev[1], true]);

        return () => thisSocket.close();
      } catch (err) {
        console.log(err);
      }
    }
  }, [authToken]);

  return { authToken, login, logout, username, authLoaded, socket};
};
