export const APP_NAME = "PENPAL";

export const APP_DESCRIPTION = "Make new friends from across the world using Penapl.SphereTown.com";

export const USERNAME_MAX_LENGTH = 40;

export const USERNAME_MIN_LENGTH = 5;

export const NAME_MAX_LENGTH = 30;

export const EMAIL_MAX_LENGTH = 100;

export const BIO_MAX_LENGTH = 1500;

export const PASSWORD_MIN_LENGTH = 8;

export const PASSWORD_MAX_LENGTH = 80;

export const URL_MAX_LENGTH = 200;

export const POST_TITLE_MAX_LENGTH = 240;

export const POST_CONTENT_MAX_LENGTH = 15000;

export const COMMENT_MAX_LENGTH = 5000;
