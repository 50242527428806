import React, { useState, Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { validateSignIn } from "../../shared/inputValidation";
import { AuthContext } from "../../shared/context/auth-context";

const SignIn = (props) => {
  const auth = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  let navigate = useNavigate();

  const signInHandler = async (event) => {
    event.preventDefault();
    setErrorMessage();

    const inputValidationResponse = await validateSignIn({
      username,
      password,
    });

    if (inputValidationResponse !== "isValid") {
      setErrorMessage(inputValidationResponse);
      return;
    }

    try {
      const url_req = process.env.REACT_APP_BACKEND_URL + "/api/auth/sign-in/";
      const apiResponseObj = await fetch(url_req, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });
      const apiResponse = await apiResponseObj.json();

      if (!apiResponse.success) {
        setErrorMessage(apiResponse.message);
        if (apiResponse.accountNotVerified) {
          props.changeContainerTo("account-activation");
        }
        return;
      }

      if (apiResponse.success) {
        auth.login(apiResponse.username, apiResponse.authToken);
        navigate("/chat", { replace: true });
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className=" rounded py-4 px-6 mt-4 flex flex-col items-center m-5 shadow-md bg-white">
      <h3 className="text-xl text-center pb-3">Sign In</h3>
      <form onSubmit={signInHandler}>
        <div className="flex flex-row  mb-2">
          <label className="text-l w-[84px] inline-block">Username</label>
          <input
            className="border ml-2 p-1 text-sm w-40"
            id="username"
            type="text"
            placeholder="Username"
            onChange={(event) => setUsername(event.target.value)}
            value={username}
          />
        </div>
        <div className="flex flex-row justify-center items-center">
          <label className="text-l w-[84px] inline-block">Password</label>
          <input
            className="border ml-2 p-1 text-sm w-40"
            id="password"
            type="password"
            placeholder="Password"
            onChange={(event) => setPassword(event.target.value)}
            value={password}
          />
        </div>
        {errorMessage && (
          <p className="text-sm text-red-500 text-center my-2 w-60">
            {errorMessage}
          </p>
        )}

        <input
          type="submit"
          value="Sign In"
          className="w-full rounded-md bg-green-400 border border-solid p-1 mt-2 hover:cursor-pointer hover:bg-green-500"
        />
      </form>
      <p
        onClick={() => props.changeContainerTo("forgot-password")}
        className="text-blue-700 text-center hover:cursor-pointer "
      >
        Forgot Passowrd
      </p>
      <p
        onClick={() => props.changeContainerTo("forgot-username")}
        className="text-blue-700 text-center hover:cursor-pointer "
      >
        Forgot Username
      </p>

      <p
        onClick={() => props.changeContainerTo("sign-up")}
        className="w-1/2 rounded-md bg-green-900 text-white border border-solid p-2 mt-4 text-center hover:cursor-pointer hover:bg-green-700"
      >
        Sign Up
      </p>
    </div>
  );
};

export default SignIn;
