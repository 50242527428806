import { useEffect, useState, useContext, useRef } from "react";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";

import { AuthContext } from "../../../shared/context/auth-context";
// import MessageList from "./MessgesList";
import MessageInput from "./MessageInput";
import MessageItem from "./MessageItem";

const MessageComponent = (props) => {
  const auth = useContext(AuthContext);
  // const [message, setMessage] = useState();
  const [chat, setChat] = useState();

  const userPair = [auth.username, props.chatWith].sort().join("-");

  // TODO-later merge getCahtByUserPair and GetNewmessages using function parameters to customize the functionality
  const getChatByUserPair = async () => {
    // setMessage();
    setChat();

    try {
      const url_req = `${process.env.REACT_APP_BACKEND_URL}/api/chat/get-chat-by-user-pair/${userPair}`;

      const apiResponseObj = await fetch(url_req, {
        headers: {
          Authorization: `Bearer ${auth.authToken}`,
        },
      });

      const apiResponse = await apiResponseObj.json();

      if (!apiResponse.success) {
        // setMessage(apiResponse.message);
      }

      if (apiResponse.success && apiResponse.messages.length > 0) {
        setChat(apiResponse.messages);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getNewmessages = async () => {
    try {
      const url_req = `${process.env.REACT_APP_BACKEND_URL}/api/chat/get-chat-by-user-pair/${userPair}`;

      const apiResponseObj = await fetch(url_req, {
        headers: {
          Authorization: `Bearer ${auth.authToken}`,
        },
      });

      const apiResponse = await apiResponseObj.json();

      if (!apiResponse.success) {
        // setMessage(apiResponse.message);
      }

      if (apiResponse.success && apiResponse.messages.length > 0) {
        setChat(apiResponse.messages);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getChatByUserPair();
  }, [userPair]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  return (
    <div className="w-full max-w-md h-[calc(100vh-64px)] flex flex-col bg-white">
      <div className="flex flex-row flex-none justify-left border-b-2  border-zinc-400 ">
        <div
          className="flex flex-row items-center justify-center py-2 px-2 hover:bg-zinc-100 hover:cursor-pointer"
          onClick={() => props.back()}
        >
          <Icon
            path={mdiChevronRight}
            size={0.7}
            horizontal
            vertical
            rotate={0}
            color="grey"
          />
        </div>
        <h1 className="pl-3 py-1 text-sm">{props.chatWith}</h1>
      </div>

      <div className="flex flex-col ">
        <div className="h-[calc(100vh-148.8px)] grow overflow-y-scroll ">
          {chat &&
            chat.map((message) => (
              <MessageItem key={message._id.toString()} message={message} />
            ))}
          <div ref={messagesEndRef} />
        </div>

        <div className="w-full flex-none ">
          <MessageInput userPair={userPair} updateMessages={getNewmessages} />
        </div>
      </div>
    </div>
  );
};

export default MessageComponent;
