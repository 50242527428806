import React, { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { validateSignIn } from "../../shared/inputValidation";
import { APP_NAME } from "../../shared/GLOBAL_CONSTANTS";

const SignIn = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [verificationEmailSent, setVerificationEamilSent] = useState(false);
  let navigate = useNavigate();

  const sendAnotherVerificationEmail = async (event) => {
    event.preventDefault();
    setErrorMessage();

    const inputValidationResponse = await validateSignIn({
      username,
      password,
    });

    if (inputValidationResponse !== "isValid") {
      setErrorMessage(inputValidationResponse);
      return;
    }

    try {
      const url_req =
        process.env.REACT_APP_BACKEND_URL +
        "/api/auth/send-another-verification-token";
      const apiResponseObj = await fetch(url_req, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });
      const apiResponse = await apiResponseObj.json();

      if (!apiResponse.success) {
        setErrorMessage(apiResponse.message);
      }
      if (apiResponse.success) {
        setVerificationEamilSent(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // TODO-now style these elements
  const credentialsForm = (
    <Fragment>
      <div className=" rounded py-4 px-6 mt-4 flex flex-col items-center m-5 shadow-md bg-white">
      <p className="font-bold  text-center">
      Account activation required to sign-in!</p>
      <p  className="text-center">
        An email was sent to you with the account activation link. Pleae check
        you email inbox (and spam).
      </p>
      <p   className="text-center my-3">
        If you no longer have access to the email, please submit the request
        below for another activation link.
      </p>
      <form onSubmit={sendAnotherVerificationEmail}>
      <div className="flex flex-row  mb-2">
          <label className="text-l w-[84px] inline-block">Username</label>
          <input
            className="border ml-2 p-1 text-sm w-40"
            id="email"
            type="email"
            placeholder="Email"
            onChange={(event) => setUsername(event.target.value)}
            value={username}
          />
        </div>
        <div className="flex flex-row justify-center items-center">
          <label className="text-l w-[84px] inline-block">Password</label>
          <input
            className="border ml-2 p-1 text-sm w-40"
            id="password"
            type="password"
            placeholder="Password"
            onChange={(event) => setPassword(event.target.value)}
            value={password}
          />
        </div>
        {errorMessage && (
          <p className="text-sm text-red-500 text-center my-2 w-60">
            {errorMessage}
          </p>
        )}
        <input
            type="submit"
            value="Send Account Activation Email"
            className="w-full rounded-md bg-green-400 border border-solid p-1 mt-2 hover:cursor-pointer hover:bg-green-500"
          />
       
      </form>
      <p
          onClick={() => props.changeContainerTo("sign-in")}
          className="text-blue-700 text-center hover:cursor-pointer "
        >
          Back
        </p>
      </div>
    </Fragment>
  );

  const emailDispatchConfirmation = (
    <div className="max-w-xs rounded py-4 px-6 mt-4 flex flex-col items-center justify-center m-5 shadow-md bg-green-50 ">
      <p className="text-center"> 
      An email is sent with the account activation link. Pleae check you email
      inbox (and spam) for the email from {APP_NAME}
    </p>
  </div>
  );

  return (
    <div>
      {!verificationEmailSent && credentialsForm}
      {verificationEmailSent && emailDispatchConfirmation}
    </div>
  );
};

export default SignIn;
