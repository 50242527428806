import { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";

import SignIn from "../HomePage/components/SignIn";

const VerifyAccountActivationPage = () => {
  let { verificationToken } = useParams();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [passwordChanged, setPasswordChanged] = useState(false);

  const changePasswordHandler = async (event) => {
    event.preventDefault();
    setErrorMessage();

    try {
      const url_req =
        process.env.REACT_APP_BACKEND_URL + "/api/auth/change-password/";
      const apiResponseObj = await fetch(url_req, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          username,
          newPassword,
          verificationToken,
        }),
      });

      const apiResponse = await apiResponseObj.json();

      if (apiResponse.success) {
        setErrorMessage(apiResponse.message);
        setPasswordChanged(true);
      } else if (!apiResponse.success) {
        setErrorMessage(apiResponse.message);
      } else {
        setErrorMessage(
          "Something went wrong. Please try again or request another link."
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  // TODO-later
  // before loading the page, check if the verificaiton token is still valid, if not say invalid req (similar to account activation)
  // if the same account is already logged in, take to chat page
  // if a different account is logged in, logout of it and show sign in page

  const resetPasswordFrom = (
    <div className=" rounded py-4 px-6 mt-4 flex flex-col justify-center items-center m-5 shadow-md bg-white top-16">
      <h3 className="text-xl text-center pb-3">Reset Passowrd</h3>
      {!passwordChanged && (
        <Fragment>
          <form onSubmit={changePasswordHandler}>
            <div className="flex flex-row  mb-2">
              <label className="text-l w-[112px] inline-block">Email</label>
              <input
                className="border ml-2 p-1 text-sm w-40"
                id="email"
                type="email"
                placeholder="Email"
                onChange={(event) => setEmail(event.target.value)}
                value={email}
              />
            </div>
            <div className="flex flex-row  mb-2">
              <label className="text-l w-[112px] inline-block">Username</label>
              <input
                className="border ml-2 p-1 text-sm w-40"
                id="username"
                type="text"
                placeholder="Username"
                onChange={(event) => setUsername(event.target.value)}
                value={username}
              />
            </div>
            <div className="flex flex-row  ">
              <label className="text-l w-[112px] inline-block">
                New Password
              </label>
              <input
                className="border ml-2 p-1 text-sm w-40"
                id="newPassword"
                type="password"
                placeholder="New Password"
                onChange={(event) => setNewPassword(event.target.value)}
                value={newPassword}
              />
            </div>
            {errorMessage && (
              <p className="text-sm text-red-500 text-center my-2 w-[280px]">
                {errorMessage}
              </p>
            )}

            <input
              type="submit"
              value="Update"
              className="w-full rounded-md bg-green-400 border border-solid p-1 mt-2"
            />
          </form>
        </Fragment>
      )}
    </div>
  );

  const resetPasswordSuccessMessage = (
    <div className="max-w-xs rounded py-4 px-6 mt-4 flex flex-col items-center m-5 shadow-md bg-green-50 ">
      <p className="font-bold">Reset Password</p>
      <p className="text-center">
        Password changed successfully. Please login using your new credentials.
      </p>
    </div>
  );

  return (
    <Fragment>
      {!passwordChanged && resetPasswordFrom}
      {passwordChanged && resetPasswordSuccessMessage}
    </Fragment>
  );
};

export default VerifyAccountActivationPage;
