import * as yup from "yup";

// future-reference: for signup show list of requirements below input box and as user types remove each that is satisfied
// future-reference: for signin, forgot password, assuming the rules may change over time, have a few hard thrisholds at client side, and if met, check the input with DB and respond accordingly

import {
  EMAIL_MAX_LENGTH,
  USERNAME_MAX_LENGTH,
  USERNAME_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
} from "./GLOBAL_CONSTANTS";

const emailValidationSchema = yup
  .string("Invalid Email")
  .required("Email is required")
  .email("Invalid Email")
  .max(
    EMAIL_MAX_LENGTH,
    `Email can not be longer than ${EMAIL_MAX_LENGTH} characters`
  );

const usernameValidationSchema = yup
  .string("Invalid Username")
  .required("Username is required")
  .matches(/^[a-zA-Z0-9]+$/, "Username must only have alphabets or numbers")
  .min(
    USERNAME_MIN_LENGTH,
    `Username must be longer than ${USERNAME_MIN_LENGTH} characters`
  )
  .max(
    USERNAME_MAX_LENGTH,
    `Username can not be longer than ${USERNAME_MAX_LENGTH} characters`
  );

const passwordValidationSchema = yup
  .string("Invalid Password")
  .required("Password is required")
  .min(
    PASSWORD_MIN_LENGTH,
    `Password must be longer than ${PASSWORD_MIN_LENGTH} characters`
  )
  .max(
    PASSWORD_MAX_LENGTH,
    `Password can not be longer than ${PASSWORD_MAX_LENGTH} characters`
  )
  .matches(
    /^(?=.*[a-z]).*$/,
    "Password must contain at least one lower case character"
  )
  .matches(
    /^(?=.*[A-Z]).*$/,
    "Password must contain at least one uppercase character"
  )
  .matches(/^(?=.*[0-9]).*$/, "Password must contain at least one number")
  .matches(
    /^(?=.*[#?!@$%^&*-]).*$/,
    "Password must contain at least one of the following special characters: # ? ! @ $ % ^ & * - "
  );

export const validateSignUp = async (input) => {
  let signUpValidationSchema = yup.object().shape({
    email: emailValidationSchema,
    username: usernameValidationSchema,
    password: passwordValidationSchema,
  });

  try {
    await signUpValidationSchema.validate(input);
  } catch (e) {
    return e.errors[0];
  }

  return "isValid";
};

export const validateSignIn = async (input) => {
  let signInValidationSchema = yup.object().shape({
    username: usernameValidationSchema,
    password: passwordValidationSchema,
  });

  try {
    await signInValidationSchema.validate(input);
  } catch (e) {
    return e.errors[0];
  }

  return "isValid";
};

export const validateEmail = async (input) => {
  try {
    await emailValidationSchema.validate(input.email);
  } catch (e) {
    return e.errors[0];
  }

  return "isValid";
};

export const validateForgotPasswordForm = async (input) => {
  let forgotPasswordFormValidationSchema = yup.object().shape({
    email: emailValidationSchema,
    username: usernameValidationSchema,
  });

  try {
    await forgotPasswordFormValidationSchema.validate(input);
  } catch (e) {
    return e.errors[0];
  }

  return "isValid";
};