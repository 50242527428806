import { useContext } from "react";

import { AuthContext } from "../../../shared/context/auth-context";

const MessageItem = (props) => {
  const auth = useContext(AuthContext);
  const d = new Date(props.message.timestamp);

  var datestring =
    " " +
    d.getHours() +
    ":" +
    d.getMinutes() +
    " " +
    d.getDate() +
    "-" +
    (d.getMonth() + 1) +
    "-" +
    d.getFullYear();

    let justify;
    if (auth.username === props.message.sender) justify = "justify-end"

  return (
    <div className={`p-1  flex ${justify}`}>
      <div className="inline-block p-1 border border-solid border-black-400 rounded-md">
        <p className="text-sm">{props.message.message}</p>
        {/* <span>{props.message.sender}</span> */}
        <p className="text-[0.6rem] ">{datestring}</p>
      </div>
    </div>
  );
};

export default MessageItem;
