import { useState, useEffect, useContext, Fragment } from "react";

import { AuthContext } from "../shared/context/auth-context";
import ConnectionList from "./conponents/ConnectionsView/ConnectionList";
import MessageComponent from "./conponents/MessagesView/MessageComponent";

const ChatPage = () => {
  const auth = useContext(AuthContext);
  // const [message, setMessage] = useState();
  const [connectionList, setConnectionList] = useState();
  const [chatWith, setChatWith] = useState();

  const getUserConnections = async () => {
    // setMessage();
    try {
      // TODO-later get by last active descing order
      const url_req = `${process.env.REACT_APP_BACKEND_URL}/api/chat/get-connection-list-by-username/${auth.username}`;

      const apiResponseObj = await fetch(url_req, {
        headers: {
          Authorization: `Bearer ${auth.authToken}`,
        },
      });

      const apiResponse = await apiResponseObj.json();

      if (apiResponse.success) {
        setConnectionList(apiResponse.connectionList);
      } else {
        // setMessage(apiResponse.message);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getUserConnections();
  }, [auth.username]);

  const handleFindNewConnection = async () => {
    // setMessage();

    try {
      const url_req =
        process.env.REACT_APP_BACKEND_URL + "/api/chat/find-new-connection";
      const apiResponseObj = await fetch(url_req, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.authToken}`,
        },
        body: JSON.stringify({
          username: auth.username,
        }),
      });
      const apiResponse = await apiResponseObj.json();

      if (!apiResponse.success) {
        // setMessage(apiResponse.message);
        return;
      }

      getUserConnections();

      auth.socket.emit("new connection", `${apiResponse.newConnection}`);
    } catch (err) {}
  };

  useEffect(() => {
    auth.socket.on("update connections", (data) => {
      getUserConnections();
    });
  });

  return (
    <div className="max-w-4xl w-full h-[calc(100vh-40px)] flex flex-col items-center">
      {auth.username && (
        <div className="max-w-4xl w-full bg-gray-200 flex-none ">
          <h6 className="w-full min-w-max text-right text-xs my-1 pr-2">
            Signed in as: {auth.username}
          </h6>
        </div>
      )}

      {!chatWith && (
        <Fragment>
          <p
            onClick={handleFindNewConnection}
            className="w-60  mx-2 mt-4 rounded-md bg-blue-300 text-white border border-solid p-2 text-center hover:bg-blue-400 hover:cursor-pointer "
          >
            Find New Connection
          </p>
          <div className="max-w-xs w-full h-[calc(100vh-122px)] grow overflow-y-scroll  mt-[16px] ">
            <ConnectionList
              connectionList={connectionList}
              setChatWith={setChatWith}
            />
          </div>
          <div className="max-w-4xl w-full bg-gray-200 flex-none ">
          <h6 className="w-full min-w-max text-center text-xs my-1 pr-2">
            For support, reach us at penpal-support@spheretown.com
          </h6>
        </div>
        </Fragment>
      )}
      {chatWith && (
        <div className="w-full max-w-md  flex flex-col flex-1 bg-white ">
          <MessageComponent chatWith={chatWith} back={setChatWith} />
        </div>
      )}
    </div>
  );
};

export default ChatPage;
