import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";

const ConnectionsItem = (props) => {
  return (
    <div
      className="flex flex-row bg-orange-50 p-2 mx-2 border-solid border  hover:bg-orange-100 hover:cursor-pointer"
      onClick={() => props.setChatWith(props.connectionUsername)}
    >
      <p className="max-w-xs w-full truncate">{props.connectionUsername}</p>
      <Icon
        path={mdiChevronRight}
        size={0.9}
        horizontal
        vertical
        rotate={180}
        color="grey"
      />
    </div>
  );
};

export default ConnectionsItem;
