import React, { useState, Fragment } from "react";

import { validateForgotPasswordForm } from "../../shared/inputValidation";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const submitHandler = async (event) => {
    event.preventDefault();
    setErrorMessage();

    const inputValidationResponse = await validateForgotPasswordForm({
      email,
      username,
    });

    if (inputValidationResponse !== "isValid") {
      setErrorMessage(inputValidationResponse);
      return;
    }

    try {
      const url_req =
        process.env.REACT_APP_BACKEND_URL + "/api/auth/forgot-password";
      const apiResponseObj = await fetch(url_req, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          username,
        }),
      });
      const apiResponse = await apiResponseObj.json();

      if (!apiResponse.success) {
        setErrorMessage(apiResponse.message);
        return;
      }

      setEmailSent(true);
    } catch (err) {
      console.log(err);
    }
  };

  const forgotPasswordForm = (
    <div className=" rounded py-4 px-6 mt-4 flex flex-col items-center m-5 shadow-md bg-white">
      <h3 className="text-xl text-center pb-3">Forgot Password</h3>
      {!emailSent && (
        <Fragment>
          <form onSubmit={submitHandler}>
            <div className="flex flex-row  mb-2">
              <label className="text-l w-[84px] inline-block">Email</label>
              <input
                className="border ml-2 p-1 text-sm w-40"
                id="email"
                type="email"
                placeholder="Email"
                onChange={(event) => setEmail(event.target.value)}
                value={email}
              />
            </div>
            <div className="flex flex-row justify-center items-center">
              <label className="text-l w-[84px] inline-block">Username</label>
              <input
                className="border ml-2 p-1 text-sm w-40"
                id="username"
                type="text"
                placeholder="Username"
                onChange={(event) => setUsername(event.target.value)}
                value={username}
              />
            </div>
            {errorMessage && (
              <p className="text-sm text-red-500 text-center my-2 w-60">
                {errorMessage}
              </p>
            )}
            <input
              type="submit"
              value="Submit"
              className="w-full rounded-md bg-green-400 border border-solid p-1 mt-2 hover:cursor-pointer hover:bg-green-500"
            />
          </form>
          <p
            onClick={() => props.changeContainerTo("forgot-username")}
            className="text-blue-700 text-center hover:cursor-pointer"
          >
            Forgot Username
          </p>

          <p
            onClick={() => props.changeContainerTo("sign-in")}
            className="w-1/2 rounded-md bg-green-900 text-white border border-solid p-2 mt-4 text-center hover:cursor-pointer hover:bg-green-700"
          >
            Sign In
          </p>
          <p
            onClick={() => props.changeContainerTo("sign-up")}
            className="w-1/2 rounded-md bg-green-900 text-white border border-solid p-2 mt-4 text-center hover:cursor-pointer hover:bg-green-700"
          >
            Sign Up
          </p>
        </Fragment>
      )}
    </div>
  );

  const forgotPasswordSuccessMessage = (
    <div className="max-w-xs rounded py-4 px-6 mt-4 flex flex-col items-center m-5 shadow-md bg-green-50 ">
      <p className="font-bold">Forgot Password</p>
      <p className="text-center">
        An email is sent with the instructions to reset password. Pleae check
        you email inbox (and spam).
      </p>
    </div>
  );

  return (
    <Fragment>
      {!emailSent && forgotPasswordForm}
      {emailSent && forgotPasswordSuccessMessage}
    </Fragment>
  );
};

export default ForgotPassword;
