import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AuthContainer from "../HomePage/components/AuthContainer";

const VerifyAccountActivationPage = () => {
  let { username, verificationToken } = useParams();

  const [errorMessage, setErrorMessage] = useState();
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    const verifyAccount = async () => {
      try {
        const apiResponseObj = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/auth/verify-account-activation/${username}/${verificationToken}`
        );

        const apiResponse = await apiResponseObj.json();

        if (apiResponse.success) {
          setVerified(true);
        } else if (!apiResponse.success) {
          setErrorMessage(apiResponse.message);
        } else {
          setErrorMessage(
            "Something went wrong. Please try again or request another link."
          );
        }
      } catch (err) {
        console.log(err);
      }
    };
    verifyAccount();
  });

  // TODO-later
  // if the same account is already logged in, take to chat page
  // if a different account is logged in, logout of it and show sign in page

  return (
    <Fragment>
      {errorMessage && (
        <div className="max-w-xs rounded py-4 px-6 mt-4 flex flex-col items-center m-5 shadow-md bg-red-50 ">
          <p className="text-sm text-red-500 text-center my-2 w-60">
            {errorMessage}
          </p>
        </div>
      )}

      {verified && (
        <div className="">
          <div className="max-w-xs rounded py-4 px-6 mt-4 flex flex-col items-center m-5 shadow-md bg-green-50 ">
            <p className="font-bold">Account Verified!</p>
          </div>

          <AuthContainer styles="top-40" />
        </div>
      )}
    </Fragment>
  );
};

export default VerifyAccountActivationPage;
