import Header from "./Header";
import Footer from "./Footer";
import { mdiPrinterPos } from "@mdi/js";




const PageLayout = (props) => {
  

  return (
    <div className="w-full h-screen bg-gray-100 flex flex-col items-center">
      <div className="flex justify-between items-center w-full max-w-4xl  bg-gray-300 h-[40px]  "> <Header /></div>
      <div className=" w-full  flex-1 max-w-4xl flex flex-col justify-start items-center">{props.children}</div>
      
      <Footer />
    </div>
  );
};

export default PageLayout;
