import React, { useState, Fragment } from "react";

import { validateSignUp } from "../../shared/inputValidation";
import { APP_NAME } from "../../shared/GLOBAL_CONSTANTS";

const SignUp = (props) => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [signUpSuccessful, setSignUpSuccessful] = useState(false);

  const signUpHandler = async (event) => {
    event.preventDefault();
    setErrorMessage();

    const inputValidationResponse = await validateSignUp({
      email,
      username,
      password,
    });

    if (inputValidationResponse !== "isValid") {
      setErrorMessage(inputValidationResponse);
      return;
    }

    try {
      const url_req = process.env.REACT_APP_BACKEND_URL + "/api/auth/sign-up/";
      const apiResponseObj = await fetch(url_req, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          username,
          password,
        }),
      });
      const apiResponse = await apiResponseObj.json();

      if (!apiResponse.success) {
        setErrorMessage(apiResponse.message);
        return;
      }

      setSignUpSuccessful(true);
    } catch (err) {
      console.log(err);
    }
  };

  const signUpFrom = (
    <div className=" rounded py-4 px-6 mt-4 flex flex-col items-center m-5 shadow-md bg-white">
      <h3 className="text-xl text-center pb-3">Sign Up</h3>
      <form onSubmit={signUpHandler}>
        <div className="flex flex-row  mb-2">
          <label className="text-l w-[84px] inline-block">Email</label>
          <input
            className="border ml-2 p-1 text-sm w-40"
            id="email"
            type="email"
            placeholder="Email"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
          />
        </div>
        <div className="flex flex-row justify-center items-center mb-2">
          <label className="text-l w-[84px] inline-block">Username</label>
          <input
            className="border ml-2 p-1 text-sm w-40"
            id="username"
            type="text"
            placeholder="Username"
            onChange={(event) => setUsername(event.target.value)}
            value={username}
          />
        </div>
        <div className="flex flex-row justify-center items-center">
          <label className="text-l w-[84px] inline-block">Password</label>
          <input
            className="border ml-2 p-1 text-sm w-40"
            id="password"
            type="password"
            placeholder="Password"
            onChange={(event) => setPassword(event.target.value)}
            value={password}
          />
        </div>

        {errorMessage && (
          <p className="text-sm text-red-500 text-center my-2 w-60">
            {errorMessage}
          </p>
        )}

        <input
          type="submit"
          value="Sign Up"
          className="w-full rounded-md bg-green-400 border border-solid p-1 mt-2 hover:cursor-pointer hover:bg-green-500"
        />
      </form>
      <p
        onClick={() => props.changeContainerTo("sign-in")}
        className="w-1/2 rounded-md bg-green-900 text-white border border-solid p-2 mt-4 text-center hover:cursor-pointer hover:bg-green-700"
      >
        Sign In
      </p>
    </div>
  );

  const signUpSuccessfulMessage = (
    <div className="max-w-xs rounded py-4 px-6 mt-4 flex flex-col items-center justify-center m-5 shadow-md bg-green-50 ">
      <p className="font-bold">
        Sign Up Successful!</p>
        <p className="text-center"> 
        An email is sent with the account activation link.
        Pleae check you email inbox (and spam) for the email from {APP_NAME}{" "}
      </p>
    </div>
  );

  return (
    <Fragment>
      {!signUpSuccessful && signUpFrom}
      {signUpSuccessful && signUpSuccessfulMessage}
    </Fragment>
  );
};

export default SignUp;
