import ConnectionsItem from "./ConnectionItem";

const ConnectionsList = (props) => {
  return (
    <div >
      {props.connectionList &&
        props.connectionList.map((connectionUsername) => (
          <ConnectionsItem
            key={connectionUsername}
            connectionUsername={connectionUsername}
            setChatWith={props.setChatWith}
          />
        ))}
    </div>
  );
};

export default ConnectionsList;
