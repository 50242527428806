import React, { useState, Fragment } from "react";

import { validateEmail } from "../../shared/inputValidation";

const ForgotUsername = (props) => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const submitHandler = async (event) => {
    event.preventDefault();
    setErrorMessage();

    const inputValidationResponse = await validateEmail({
      email,
    });

    if (inputValidationResponse !== "isValid") {
      setErrorMessage(inputValidationResponse);
      return;
    }

    try {
      const url_req =
        process.env.REACT_APP_BACKEND_URL + "/api/auth/forgot-username";
      const apiResponseObj = await fetch(url_req, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });
      const apiResponse = await apiResponseObj.json();

      if (!apiResponse.success) {
        setErrorMessage(apiResponse.message);
        return;
      }

      setEmailSent(true);
    } catch (err) {
      console.log(err);
    }
  };

  const forgotUsernameFrom = (
    <div className=" rounded py-4 px-6 mt-4 flex flex-col items-center m-5 shadow-md bg-white">
      <h3 className="text-xl text-center pb-3">Forgot Username</h3>

      <Fragment>
        <form onSubmit={submitHandler}>
          <div className="flex flex-row  mb-2">
            <label className="text-l w-[84px] inline-block">Email</label>
            <input
              className="border ml-2 p-1 text-sm w-40"
              id="email"
              type="email"
              placeholder="Email"
              onChange={(event) => setEmail(event.target.value)}
              value={email}
            />
          </div>
          {errorMessage}
          <input
            type="submit"
            value="Submit"
            className="w-full rounded-md bg-green-400 border border-solid p-1 mt-2 hover:cursor-pointer hover:bg-green-500"
          />
        </form>
        <p
          onClick={() => props.changeContainerTo("forgot-password")}
          className="text-blue-700 text-center hover:cursor-pointer "
        >
          Forgot Passowrd
        </p>

        <p
          onClick={() => props.changeContainerTo("sign-in")}
          className="w-1/2 rounded-md bg-green-900 text-white border border-solid p-2 mt-4 text-center hover:cursor-pointer hover:bg-green-700"
        >
          Sign In
        </p>
        <p
          onClick={() => props.changeContainerTo("sign-up")}
          className="w-1/2 rounded-md bg-green-900 text-white border border-solid p-2 mt-4 text-center hover:cursor-pointer hover:bg-green-700"
        >
          Sign Up
        </p>
      </Fragment>
    </div>
  );

  const forgotUsernameSuccessMessage = (
    <div className="max-w-xs rounded py-4 px-6 mt-4 flex flex-col items-center m-5 shadow-md bg-green-50 ">
      <p className="font-bold">Forgot Username</p>
      <p className="text-center">
        If an account exists with the Email address, you will receive an email
        with the username. Pleae check you email inbox (and spam) for the email.
      </p>
    </div>
  );

  return (
   <Fragment>
      {!emailSent && (
        forgotUsernameFrom
      )}
      {emailSent && forgotUsernameSuccessMessage}
    </Fragment>
  );
};

export default ForgotUsername;
