import { Fragment, useState } from "react";

import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import ForgotUsername from "./ForgotUsername";
import AccountActivation from "./AccountActivation"

const AuthContainer = (props) => {
  const [authComponentToShow,setAuthComponentToShow] = useState("sign-in")

  let containerContent;



  
  if (authComponentToShow === 'sign-up') containerContent = <SignUp changeContainerTo={setAuthComponentToShow} />
  if (authComponentToShow === 'sign-in') containerContent = <SignIn changeContainerTo={setAuthComponentToShow} />
  if (authComponentToShow === 'account-activation') containerContent = <AccountActivation changeContainerTo={setAuthComponentToShow} />
  if (authComponentToShow === 'forgot-password') containerContent = <ForgotPassword changeContainerTo={setAuthComponentToShow} />
  if (authComponentToShow === 'forgot-username') containerContent = <ForgotUsername changeContainerTo={setAuthComponentToShow} />

  return <div className="max-w-xs">{containerContent}</div>
}

export default AuthContainer;